.project {
    height: 200px;
    width: 300px;

    background-color: white;
    border: 1px solid black;
    padding: 10px;
    margin: 15px;
    cursor: pointer;

    h3,
    p {
        margin-bottom: 0px;
        color: black;
    }

    img {
        display: block;
        width: 250px;
        height: 150px;
        margin: auto;
    }
}

.projectModal {
    min-height: 100%;
    width: 100%;
    position: absolute;
    background-color: white;
    color: black;
    border: 1px solid black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img,
    p {
        margin-left: 20px;
        margin-right: 20px;
    }

    img {
        margin-top: 10px;
        width: 60%;
        height: auto;
    }

}


#CloseModal {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
}