@use "global.scss";

@font-face {
    font-family: "Jetbrains Mono";
    src: url(../fonts/JetBrainsMono-Italic-VariableFont_wght.ttf) format("truetype");
}

@font-face {
    font-family: "Jetbrains Mono";
    src: url(../fonts/JetBrainsMono-VariableFont_wght.ttf) format("truetype");
}

body,
html {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: global.$primary-color;

    overflow: overlay;
    overflow-x: hidden;

    font-family: "Jetbrains Mono";

    scroll-behavior: smooth;
}

.page-container {
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.anchor {
    display: block;
    height: 60px;
    margin-top: -60px;
    visibility: hidden;
}

.hero-bg {
    position: relative;
    display: flex;
    height: 100vh;
    grid-column: span 12;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: global.$primary-color;
    background-image: linear-gradient(to bottom, transparent 70%, global.$primary-color 95%), url("../../public/images/background/geisel-blur.png");
    background-repeat: no-repeat;
    background-size: cover;

    .icon {
        margin: auto auto 0 auto;
    }

    p {
        margin-bottom: 30vh;
        color: white;
    }

    .down-container {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        .down {
            cursor: pointer;
            animation: hover 1s infinite;
        }
    }
}

section {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    min-height: 100vh;
    grid-column: span 12;

    .content {
        grid-column: 2 / span 10;
        color: white;
        min-height: 80%;
        width: 100%;
        margin: auto;
        opacity: 0;
        filter: drop-shadow(6px 6px 7px #00000060);

        animation: fade-in-left 0.5s ease-in;
        animation-fill-mode: forwards;
    }

    .halfContent {
        grid-column: 2 / span 5;
        color: white;
        min-height: 80%;
        width: 100%;
        margin: auto;
        opacity: 0;
        filter: drop-shadow(6px 6px 7px #00000060);

        animation: fade-in-left 0.5s ease-in;
        animation-fill-mode: forwards;

        img {
            margin-left: 40px;
            margin-bottom: 30px;
            object-fit: contain;
            width: 100%;
        }
    }
}

.skillContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
}

.projectContainer {
    display: flex;
    flex-wrap: wrap;
}

footer {
    grid-column: span 12;
    height: 150px;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: grey;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Animations */
@keyframes hover {
    0% {
        transform: translateY(-10%);
    }

    50% {
        transform: translateY(10%);
    }

    100% {
        transform: translateY(-10%);
    }

}

@keyframes fade-in-left {
    from {
        opacity: 0;
        transform: translateX(-30%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }

}

p {
    overflow: auto;
    max-height: 800px;
}

@media only screen and (max-width: 800px) {
    section .halfContent {
        grid-column: 2 / span 10 !important;
    }
}